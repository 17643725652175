<template>
  <v-footer class="px-0 d-flex flex-column">
    <v-container>
      <v-row no-gutters class="mt-4">
        <v-col cols="12">
          <v-row
            no-gutters
            v-if="links && $vuetify.breakpoint.mdAndUp"
            justify="start"
          >
            <!-- custom layout for column with "socials" CSS class, showing button for app download -->
            <!-- "socials" column not rendered on mobile app -->
            <v-col
              v-for="(link, index) in links"
              :key="index"
              cols="12"
              sm="4"
              md="3"
              class="nav-col d-flex flex-column justify-start align-center align-md-start black--text pr-md-2 py-2"
            >
              <h2
                class="text-center text-h5 font-weight-bold text-sm-left text-uppercase mt-2"
              >
                {{ link.name }}
              </h2>
              <div
                v-for="child in link.templateNavItems"
                :key="child.templateNavItemId"
                class="pa-1"
              >
                <template v-if="child.templateNavItems?.length === 0">
                  <a
                    v-if="child.itemUrl && child.itemUrl !== ''"
                    :aria-label="linkName(child)"
                    :title="linkName(child)"
                    :id="`footer-menu-${child.templateNavItemId}`"
                    :href="child.itemUrl || '#'"
                    class="default--text text-decoration-none"
                    :class="[child.classStyle, { 'no-evt': index == 2 }]"
                    :target="child.target || '_self'"
                    @click="onLinkClick"
                  >
                    {{ child.name }}
                  </a>
                  <span
                    v-else
                    :key="child.templateNavItemId"
                    class="default--text text-decoration-none"
                    :class="child.classStyle"
                    >{{ child.name }}</span
                  >
                </template>
                <div v-else>
                  <h2
                    class="text-center text-h5 font-weight-bold text-sm-left text-uppercase mt-6"
                  >
                    {{ child.name }}
                  </h2>
                  <template v-for="child2 in child.templateNavItems">
                    <a
                      v-if="child2.itemUrl && child2.itemUrl !== ''"
                      :aria-label="linkName(child2)"
                      :title="linkName(child2)"
                      :key="child2.templateNavItemId"
                      :id="`footer-menu-${child2.templateNavItemId}`"
                      :href="child2.itemUrl"
                      class="default--text text-decoration-none"
                      :class="child.classStyle"
                      :target="child2.target || '_self'"
                      @click="onLinkClick"
                    >
                      <img
                        height="52"
                        width="52"
                        :src="child2.iconSource"
                        :alt="child2.name"
                        class="mr-2"
                      />
                    </a>
                    <img
                      v-else
                      :key="child2.templateNavItemId"
                      :src="child2.iconSource"
                      :alt="child2.name"
                      class="mr-2"
                    />
                  </template>
                </div>
              </div>
            </v-col>
          </v-row>
          <div v-else-if="links && $vuetify.breakpoint.smAndDown">
            <v-expansion-panels
              accordion
              flat
              tile
              :focusable="selectedTab"
              class="px-4 footer-expansion"
              v-model="panel"
            >
              <v-expansion-panel
                v-for="(link, index) in links"
                :key="index"
                :aria-controls="'panel-content-' + link.templateNavItemId"
                role="button"
              >
                <v-expansion-panel-header
                  class="expansion-title "
                  :aria-controls="'panel-content-' + link.templateNavItemId"
                  role="button"
                  :aria-expanded="
                    panel.includes(link.templateNavItemId) ? 'true' : 'false'
                  "
                >
                  <template v-slot:actions>
                    <v-icon small>$chevronDown</v-icon>
                  </template>
                  <span class="font-weight-bold text-uppercase">{{
                    link.name
                  }}</span>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="mt-2">
                  <div
                    v-for="child in link.templateNavItems"
                    :key="child.templateNavItemId"
                    class="pa-1"
                  >
                    <a
                      :aria-label="linkName(child)"
                      :title="linkName(child)"
                      v-if="child.templateNavItems?.length === 0"
                      :href="child.itemUrl || '#'"
                      class="d-block default--text text-decoration-none"
                      :class="[child.classStyle, { 'no-evt': index == 2 }]"
                      :target="child.target"
                      @click="onLinkClick"
                    >
                      <span :class="child.classStyle">{{ child.name }}</span>
                    </a>
                    <div v-else>
                      <h2
                        class="font-weight-bold text-h5 text-sm-left text-uppercase mt-6"
                      >
                        {{ child.name }}
                      </h2>
                      <a
                        :aria-label="linkName(child2)"
                        :title="linkName(child2)"
                        v-for="child2 in child.templateNavItems"
                        :key="child2.templateNavItemId"
                        :id="`footer-menu-${child2.templateNavItemId}`"
                        :href="child2.itemUrl"
                        class="default--text text-decoration-none"
                        :class="child.classStyle"
                        :target="child2.target || '_self'"
                        @click="onLinkClick"
                      >
                        <img
                          height="52"
                          width="52"
                          :src="child2.iconSource"
                          :alt="child2.name"
                          class="mr-2"
                        />
                        <!-- <span>{{ child2.name }}</span> -->
                      </a>
                    </div>
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <v-container>
      <v-divider class="grey w-100" />
      <v-row class="py-3 px-3 px-md-0">
        <v-col
          cols="12"
          sm="6"
          class="d-flex align-start align-md-center justify-start justify-md-space-between flex-column flex-md-row"
        >
          <div class="font-weight-bold my-2">
            {{ $t("footer.downloadApp") }}
          </div>
          <div class="d-flex">
            <a
              aria-label="Scarica l'applicazione Coop su App store"
              title="Scarica l'applicazione Coop su App store"
              href="https://apps.apple.com/it/app/coopshop/id1517579789?platform=iphone"
              target="_blank"
            >
              <img
                width="156"
                height="45"
                class="d-block px-1"
                src="/img_layout/footer/app-store.png"
                alt="Immagine app store"
                style="max-width: 100%;"
              />
            </a>
            <a
              aria-label="Scarica l'applicazione Coop su Play store"
              title="Scarica l'applicazione Coop su Play store"
              href="https://play.google.com/store/apps/details?id=it.digitelematica.ebsn.ccno"
              target="_blank"
            >
              <img
                width="156"
                height="45"
                class="d-block px-1"
                src="/img_layout/footer/google-play.png"
                alt="Immagine google play"
                style="max-width: 100%;"
              />
            </a>
          </div>
        </v-col>
        <v-col
          cols="12"
          sm="6"
          class="d-flex align-start align-md-center justify-start flex-column flex-md-row"
        >
          <div class="font-weight-bold my-2 mr-2">
            {{ $t("footer.payment") }}
          </div>
          <div class="d-flex align-center logo-gap justify-start">
            <div
              v-for="(item, idx) in payments"
              :key="idx"
              class="d-flex align-center"
            >
              <img
                :width="item.width"
                height="30"
                :aria-label="item.name"
                class="d-block"
                :src="`/credit-card/${item.icon}.png`"
                :alt="`Immagine ${item.icon}`"
              />
            </div>
          </div>
        </v-col>
      </v-row>
      <v-layout
        class="footer-copyright align-center justify-center d-inline-flex py-4 primary white--text w-100"
        :column="$vuetify.breakpoint.xs"
      >
        <div
          class="text-caption mx-3 mx-sm-10"
          v-html="$t('footer.copyright', [version])"
        />
      </v-layout>
    </v-container>
  </v-footer>
</template>
<style scoped lang="scss">
.v-footer {
  border-top: 13px solid $primary !important;
  background-color: var(--v-grey-lighten2);
}
.container {
  background-color: var(--v-grey-lighten2);
}
.v-expansion-panel-header {
  border-bottom: 1px solid $border-color !important;
}
.logo-gap {
  column-gap: 7px;
}
.footer-copyright {
  font-size: 13px;
  margin-bottom: 40px;
  border-radius: 8px;
}
.nav-col:last-child {
  color: var(--v-black-base) !important;
  background-color: var(--v-grey-base) !important;
  border-radius: 8px;
  padding-right: 0px;
  padding-left: 8px;
}
.nav-col {
  > div:last-child {
    margin-bottom: 0px;
    margin-top: auto;
  }
}
.footer-expansion {
  ::v-deep .v-expansion-panel-header {
    background-color: var(--v-grey-lighten2) !important;
  }
  ::v-deep .v-expansion-panel {
    background-color: var(--v-grey-lighten2) !important;
  }
  .expansion-title {
    color: var(--v-black-base) !important;
  }
  .v-expansion-panel:last-of-type {
    .expansion-title {
      color: var(--v-black-base) !important;
    }
  }
}
</style>

<script>
import clickHandler from "@/commons/mixins/clickHandler";
import get from "lodash/get";

export default {
  name: "Footer",
  mixins: [clickHandler],
  props: {
    links: { type: Array, required: true },
    links2: { type: Array, required: true },
    applicationConfig: { type: Object, required: false }
  },
  data() {
    return {
      payments: [
        {
          icon: "mastercard-curved-64px",
          name: "Pagamento mastercard",
          width: 47
        },
        {
          icon: "visa-curved-64px",
          name: "Pagamento Visa",
          width: 47
        },
        {
          icon: "paypal-curved-64px",
          name: "Pagamento Paypal",
          width: 47
        },
        {
          icon: "amex-curved-64px",
          name: "Pagamento Amex",
          width: 47
        },
        {
          icon: "satispay",
          name: "Pagamento Satispay",
          width: 97
        }
      ],
      selectedTab: null,
      panel: []
    };
  },
  computed: {
    version() {
      return global.config.version;
    },
    logoDesktop() {
      return get(
        this.applicationConfig,
        "metaData.template_model.LOGO_DESKTOP"
      );
    },
    logoMobile() {
      return get(this.applicationConfig, "metaData.template_model.LOGO_MOBILE");
    }
  },
  methods: {
    isSocialLinksColumn(classes) {
      return classes?.search("social") > -1;
    },
    isCordovaPlatform(classes) {
      return this.isSocialLinksColumn(classes) && this.cordovaPlatform;
    },
    isCreditsClass(classes) {
      return classes.search("classes") > -1;
    },
    openCookieBotWidget() {
      // eslint-disable-next-line no-undef
      Cookiebot.show();
    },
    linkName(child) {
      return child?.name.length > 1 ? child.name : "Vai al sito";
    },
    onLinkClick(e) {
      var url = e.currentTarget.href;
      let fileName = "";
      this.$emit("close");
      this.$nextTick(() => {
        if (
          this.$platform_is_cordova &&
          e.currentTarget.href.endsWith(".pdf")
        ) {
          fileName = url.substring(url.lastIndexOf("/") + 1, url.length);
          fileName = fileName.substring(0, fileName.length - 4);
          this.handleReceiptDownload(url, fileName);
        } else if (e.target.className.includes("cookie-widget-trigger")) {
          if (window.Cookiebot) {
            window.Cookiebot.show();
          }
        } else {
          this.clicked(e);
        }
      });
    },
    goToExternalPage(url) {
      if (this.$platform_is_cordova && url.indexOf("http") > -1) {
        //if app open on _system browser
        window.cordova.InAppBrowser.open(url, "_system");
      } else {
        window.open(url, "_blank");
      }
    }
  }
};
</script>
